import request from '@/utils/request';
class home {
  constructor() {}
  // 获取短信验证码
  send(phone, login) {
    return request({
      url: `/api/login/send?phone=${phone}&scene=${login}`,
      method: 'get',
      data: {},
    });
  }
  // 解决方案列表
  solution(limits, pages, obj) {
    console.log(obj, '141414');
    return request({
      url: `/api/solution/index?limit=${limits}&page=${pages}&filter=${obj}`,
      method: 'get',
      data: {},
    });
  }
  // 解决方案列表详情
  solutionDetail(id) {
    return request({
      url: `/api/solution/find?id=${id}`,
      method: 'get',
      data: {},
    });
  }

  // 资讯列表
  information(categorys, limits, pages) {
    return request({
      url: `/api/information/index?category=${categorys}&limit=${limits}&page=${pages}`,
      method: 'get',
      data: {},
    });
  }

  // 资讯列表详情
  informationDetail(id) {
    return request({
      url: `/api/information/find?id=${id}`,
      method: 'get',
      data: {},
    });
  }
  // 行业列表
  industry(data) {
    return request({
      url: `/api/industry/index`,
      method: 'get',
      data,
    });
  }
  // 场景列表
  scenes(data) {
    return request({
      url: '/api/scenes/index',
      method: 'get',
      data,
    });
  }

  // 申请商家入驻
  merchant(data) {
    return request({
      url: '/api/apply.settled/add',
      method: 'post',
      data,
    });
  }

  // 申请试用
  apply(data) {
    return request({
      url: '/api/apply.trail/add',
      method: 'post',
      data,
    });
  }
}

export default new home();
