<template>
  <div class="app-wrapper">
    <app-header />
    <app-main class="app-main" />
    <app-footer v-if="this.$route.path != '/home'" />
    <div class="serve" @click="windowClick">
      <img src="../assets/img/service.png" alt="" />
    </div>
  </div>
</template>

<script>
import { AppMain } from './components';
import header from '@/layout/components/header';
import footer from '@/layout/components/footer';
export default {
  name: 'Layout',
  mixins: [],
  components: {
    appHeader: header,
    appFooter: footer,
    AppMain,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 客服打开新窗口
    windowClick() {
      window.open(
        'https://kf.ruanzhongzi.com/pcKefu/?propType=1&kefuId=chaiyeshangbang  ',
        '_blank'
      );
    },
  },
};
</script>

<style scoped lang="scss">
.app-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .app-main {
    flex-grow: 1;
  }
  .serve {
    width: 1rem;
    height: 1rem;
    position: fixed;
    right: 0.5rem;
    bottom: 0.5rem;
    z-index: 200;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
</style>
