import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
import Layout from '@/layout';

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: '/home',
        component: () => import('@/views/Home/index.vue'),
        meta: { title: 'Home' },
      },
      {
        path: 'solution',
        name: '/solution',
        component: () => import('@/views/solution/index.vue'),
        meta: { title: 'solution' },
      },
      {
        path: 'product',
        name: '/product',
        component: () => import('@/views/product/index.vue'),
        meta: { title: 'product' },
      },
      {
        path: 'consulting',
        name: '/consulting',
        component: () => import('@/views/consulting/index.vue'),
        meta: { title: 'consulting' },
      },
      {
        path: 'about',
        name: '/about',
        component: () => import('@/views/about/index.vue'),
        meta: { title: 'about' },
      },
      {
        path: 'consultingDetail',
        name: '/consultingDetail',
        component: () => import('@/views/consultingDetail/index.vue'),
        meta: { title: 'consultingDetail' },
      },
      {
        path: 'productCenter',
        name: '/productCenter',
        component: () => import('@/views/productCenter/index.vue'),
        meta: { title: 'productCenter' },
      },
      {
        path: 'client',
        name: '/client',
        component: () => import('@/views/client/index.vue'),
        meta: { title: 'client' },
      },
      {
        path: 'solutionDetail',
        name: '/solutionDetail',
        component: () => import('@/views/solutionDetail/index.vue'),
        meta: { title: 'solutionDetail' },
      },
      {
        path: 'industry',
        name: '/industry',
        component: () => import('@/views/industry/index.vue'),
        meta: { title: 'industry' },
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
