<template>
  <div
    :class="{
      'header-wrap': !isHighlightActive,
      'acc-wrap': isHighlightActive,
    }"
    @mouseenter="caseentry"
    @mouseleave="caseLeave"
  >
    <div class="header-content">
      <div class="logo">
        <img v-if="flag" src="../../assets/img/logo.png" alt="" />
        <img v-else src="../../assets/img/logo_2.png" alt="" />
      </div>
      <div class="nav">
        <ul>
          <li
            v-for="(item, index) in nav"
            :key="index"
            @mouseenter="showDropdown(index)"
            @mouseleave="leave"
          >
            <span
              :class="index == indexs ? 'active' : 'li'"
              @click="skip(item, index)"
              >{{ item.name }}</span
            >
            <span class="span" v-if="index == indexs"></span>
            <div v-if="item.children">
              <ul v-if="activeIndex === index" class="li-item">
                <li
                  v-for="(dropdownItem, dropdownIndex) in item.children"
                  :key="dropdownIndex"
                  @click="skip(dropdownItem, index)"
                >
                  <a>{{ dropdownItem.title }}</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <div class="phone">
          <img v-if="flag" src="../../assets/img/phone.png" alt="" />
          <img v-else src="../../assets/img/home-phone.png" alt="" />
          <span class="span">400-123-123</span>
        </div>
        <div class="free">
          <div class="free-title" @click="alertMark">免费使用</div>
        </div>
        <div class="download" @mouseenter="tabshover" @mouseleave="leaves">
          <div class="download-app">下载App</div>
          <div class="download-code" v-if="downloadFlag">
            <img src="../../assets/img/download-code.png" alt="" />
          </div>
        </div>
      </div>
      <div class="mark-boxs" v-if="markFlag">
        <div class="mark">
          <div class="mark-content">
            <div class="top">
              <div class="title">申请免费试用</div>
              <div class="descript">
                请完善一下新信息，我们将在1个工作日内联系您
              </div>
              <img @click="close" src="../../assets/img/cha.png" alt="" />
            </div>
            <div class="form">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="180px"
                class="demo-ruleForm"
              >
                <el-form-item prop="company_name" class="lab">
                  <el-input
                    class="e-input"
                    v-model="ruleForm.company_name"
                    placeholder="请输入企业名称"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="name" class="lab">
                  <el-input
                    class="e-input"
                    v-model="ruleForm.name"
                    placeholder="联系人姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="position" class="lab">
                  <el-input
                    class="e-input"
                    v-model="ruleForm.position"
                    placeholder="您的职业"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="phone" class="lab">
                  <el-input
                    class="e-input"
                    v-model="ruleForm.phone"
                    placeholder="请输入联系人手机号码"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="code" class="custom-form-item">
                  <el-input
                    v-model="ruleForm.code"
                    placeholder="请输入验证码"
                    class="custo-input"
                  ></el-input>
                  <el-button class="code" @click="code">获取验证码</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="submit" @click="submitForm('ruleForm')">立即提交</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from '@/api/user';
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      // actives: '/home',
      indexs: 0,
      flag: true,
      activeIndex: -1,
      isHighlightActive: false,
      downloadFlag: false,
      markFlag: false,
      ruleForm: {
        company_name: '',
        name: '',
        position: '',
        phone: '',
        code: '',
      },
      rules: {
        company_name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        position: [
          { required: true, message: '请输入您的职业', trigger: 'blur' },
        ],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      nav: [
        {
          name: '首页',
          path: '/home',
        },
        {
          name: '解决方案',
          path: '/solution',
        },
        {
          name: '产品中心',
          path: '/productCenter',
          children: [
            {
              title: '拓客管理系统',
              path: '/productCenter',
            },
            {
              title: '客户管理系统',
              path: '/client',
            },
          ],
        },
        {
          name: '关于我们',
          path: '/about',
        },
        {
          name: '商户入驻',
          path: '/product',
        },
        {
          name: '资讯中心',
          path: '/consulting',
        },
        {
          name: '产业AI',
          path: '/industry',
        },
      ],
      // logins: login,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    // 获取验证码
    code() {
      if (this.ruleForm.phone == '') {
        alert('手机号码不能为空');
      } else if (this.validatePhoneNumber(this.ruleForm.phone)) {
        alert('请输入正确的手机号');
      } else {
        user
          .send(this.ruleForm.phone, 'card')
          .then((res) => {
            // this.codes = res.data.code;
            // alert(`验证码为 ~ ${res.data.code}`);
            // console.log(res, '134验证码');
          })
          .catch((err) => []);
      }
    },
    validatePhoneNumber(phoneNumber) {
      // 使用正则表达式进行手机号验证
      var pattern = /^1[0-9]{10}$/;
      return !pattern.test(phoneNumber);
    },
    // 提交
    submitForm(formName) {
      this.types = 1;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.validatePhoneNumber(this.ruleForm.phone)) {
            alert('请输入正确的手机号');
          } else {
            user
              .apply(this.ruleForm)
              .then((res) => {
                alert(res.msg);
                this.ruleForm.name = '';
                this.ruleForm.company_name = '';
                this.ruleForm.phone = '';
                this.ruleForm.code = '';
                this.ruleForm.position = '';
                this.markFlag = false;
              })
              .catch((err) => []);
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 打开弹框
    alertMark() {
      this.markFlag = true;
    },
    // 关闭弹框
    close() {
      this.markFlag = false;
    },
    //鼠标指向
    caseentry() {
      this.flag = false;
    },
    //鼠标离开
    caseLeave() {
      this.flag = true;
    },
    // 下拉菜单
    showDropdown(index) {
      // this.indexs = index;
      // 高亮切换
      // this.actives = item.path;
      if (this.activeIndex === index) {
        this.activeIndex = -1;
      } else {
        this.activeIndex = index;
      }
    },
    leave() {
      this.activeIndex = -1;
      // this.indexs = -1;
    },
    handleScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop === 0) {
        // 滚动到页面顶部，取消高亮
        this.isHighlightActive = false;
        this.flag = true;
      } else {
        // 其他情况，往上滚动，添加高亮
        this.isHighlightActive = true;
        this.flag = false;
      }
    },
    skip(item, index) {
      this.indexs = index;
      this.$router.push(item.path).catch((err) => {});
    },
    // 下载app指向展示
    tabshover() {
      this.downloadFlag = true;
    },
    leaves() {
      this.downloadFlag = false;
    },
  },
  watch: {
    // $route(to, from) {
    //   // 监听 $route 对象的变化
    //   // 可以执行相应的逻辑
    //   // 例如：根据路由的变化重新加载数据
    //   console.log(to, from, 'route');
    //   this.actives = to.path;
    // },
  },
  // created() {
  //   this.skip(this.$route);
  //   console.log(this.$route, '6666');
  // },
};
</script>

<style scoped lang="scss">
.header-wrap:hover {
  width: 100%;
  height: 0.875rem;
  position: fixed;
  // background: rgba(0, 0, 0, 0.1);
  // min-width: 15.375rem;
  // overflow-x: auto;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  .header-content {
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    // margin-left: 3.25rem;
    .logo {
      width: 2.0375rem;
      height: 0.5rem;
      margin-right: 1.4125rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .nav {
      width: calc(100% - 2.8rem);
      height: 0.875rem;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      ul {
        width: 8rem;
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        li {
          width: 1.25rem;
          text-align: center;
          display: inline-block;
          line-height: 1rem;
          // margin-right: .825rem;
          text-decoration: none;
          font-size: 0.2rem;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          .active {
            font-weight: bold;
            color: #0057fe;
            // border-bottom: .025rem solid #000;
          }
          .li {
            color: #000000;
          }
          .span {
            width: 32px;
            height: 0.05rem;
            background: #0057fe;
            display: block;
            text-align: center;
            margin-top: -0.2rem;
          }
          .li-item {
            width: 1.25rem;

            // width: 1.25rem;
            background: #ffffff;
            // border-radius: 15px;
            z-index: 1000;
            // padding: 0px 10px;
            position: absolute;
            // margin-left: -0.2rem;
            // text-align: center;
            list-style: none;
            // line-height: 30px;
            display: flex;
            flex-direction: column;
            margin-top: -0.05rem;
            margin-left: -0.58rem;
            li {
              // width: 120px;
              height: 0.4375rem;
              line-height: 0.4375rem;
              font-size: 14px;
              font-weight: 400;
              color: #000000;
            }
            li:hover {
              font-weight: bold;
              color: #0057fe;
            }
          }
        }
      }
      .phone {
        display: flex;
        margin-left: 0.3rem;
        img {
          width: 0.2rem;
          height: 0.2rem;
          margin-top: 0.0375rem;
        }
        span {
          display: block;
          width: 1.325rem;
          height: 0.25rem;
          font-size: 0.2rem;
          font-weight: 400;
          color: #000000;
          line-height: 0.25rem;
          margin-left: 0.0625rem;
        }
      }
      .free {
        width: 1.2rem;
        height: 0.45rem;
        font-size: 0.15rem;
        color: #ffffff;
        background: #0057fe;
        border-radius: 0.225rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.3rem;
      }
      .download {
        position: relative;
        .download-app {
          width: 1.2rem;
          height: 0.45rem;
          font-size: 0.15rem;
          color: #ffffff;
          background: #34d7e8;
          border-radius: 0.225rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0.3rem;
        }
        .download-code {
          width: 1.925rem;
          height: 1.9625rem;
          position: absolute;
          top: 0.5625rem;
          right: -0.35rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .mark-boxs {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      position: fixed;
      top: 0rem;
      left: 0rem;
      z-index: 999999999;
      .mark {
        width: 11.625rem;
        height: 10.6625rem;
        background: #ffffff;
        position: fixed;
        left: calc(50% - 5.8125rem);
        top: calc(50% - 5.3312rem);
        z-index: 9899999999999;
        .mark-content {
          display: flex;
          flex-direction: column;
          // justify-content: center;
          // align-items: center;
          position: relative;
          .top {
            width: 11.625rem;
            height: 2.175rem;
            background: url(../../assets/img/home-flag.png) 100%;
            position: relative;
            .title {
              width: 8.1125rem;
              height: 0.5625rem;
              font-size: 0.4rem;
              font-weight: 500;
              color: #0057fe;
              margin: 0.75rem 0rem 0rem 1.7625rem;
            }
            .descript {
              width: 8.1125rem;
              height: 0.275rem;
              font-size: 0.2rem;
              font-weight: 500;
              color: #999999;
              margin: 0.25rem 0rem 0rem 1.7625rem;
            }
            img {
              width: 0.45rem;
              height: 0.45rem;
              position: absolute;
              top: 0.375rem;
              right: 0.375rem;
            }
          }
          ::v-deep {
            .form {
              margin-top: 0.25rem;
              margin-left: 0rem;
              .el-form-item__content {
                width: 5.7125rem;
                height: 0.85rem;
                .el-input__inner {
                  height: 0.85rem;
                }
              }
              .el-form-item {
                width: 8.1125rem;
                margin-top: 0.25rem;
                text-align: left;
              }
              margin-left: 0.8rem;
              .el-input_inner {
                width: 8.1125rem;
              }
            }
            .custom-form-item {
              width: 5.25rem;
            }
            .custom-form-item .code {
              width: 2.5rem;
              height: 0.85rem;
              font-size: 0.225rem;
              font-weight: 400;
              color: #ffffff;
              background: #0057fe;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              left: 3.3rem;
              top: 0;
            }
          }
          .submit {
            width: 3.125rem;
            height: 0.85rem;
            font-size: 0.225rem;
            color: #ffffff;
            background: #0057fe;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.75rem;
            margin-left: 4.25rem;
          }
        }
      }
    }
  }
}
.acc-wrap {
  width: 100%;
  height: 0.875rem;
  position: fixed;
  // background: rgba(0, 0, 0, 0.1);
  // min-width: 15.375rem;
  // overflow-x: auto;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  .header-content {
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    // margin-left: 3.25rem;
    .logo {
      width: 2.0375rem;
      height: 0.5rem;
      margin-right: 1.4125rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .nav {
      width: calc(100% - 2.8rem);
      height: 0.875rem;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      ul {
        width: 8rem;
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        li {
          width: 1.25rem;
          text-align: center;
          display: inline-block;
          line-height: 1rem;
          // margin-right: .825rem;
          text-decoration: none;
          font-size: 0.2rem;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          .active {
            font-weight: bold;
            color: #0057fe;
            // border-bottom: .025rem solid #000;
          }
          .li {
            color: #000000;
          }
          .span {
            width: 32px;
            height: 0.05rem;
            background: #0057fe;
            display: block;
            text-align: center;
            margin-top: -0.2rem;
          }
          .li-item {
            width: 1.25rem;

            // width: 1.25rem;
            background: #ffffff;
            // border-radius: 15px;
            z-index: 1000;
            // padding: 0px 10px;
            position: absolute;
            // margin-left: -0.2rem;
            // text-align: center;
            list-style: none;
            // line-height: 30px;
            display: flex;
            flex-direction: column;
            margin-top: 0.1rem;
            margin-left: -0.58rem;
            li {
              // width: 120px;
              height: 0.4375rem;
              line-height: 0.4375rem;
              font-size: 14px;
              font-weight: 400;
              color: #000000;
            }
            li:hover {
              font-weight: bold;
              color: #0057fe;
            }
          }
        }
      }
      .phone {
        display: flex;
        margin-left: 0.3rem;
        img {
          width: 0.2rem;
          height: 0.2rem;
          margin-top: 0.0375rem;
        }
        span {
          display: block;
          width: 1.325rem;
          height: 0.25rem;
          font-size: 0.2rem;
          font-weight: 400;
          color: #000000;
          line-height: 0.25rem;
          margin-left: 0.0625rem;
        }
      }
      .free {
        width: 1.2rem;
        height: 0.45rem;
        font-size: 0.15rem;
        color: #ffffff;
        background: #0057fe;
        border-radius: 0.225rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.3rem;
      }
      .download {
        position: relative;
        .download-app {
          width: 1.2rem;
          height: 0.45rem;
          font-size: 0.15rem;
          color: #ffffff;
          background: #34d7e8;
          border-radius: 0.225rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0.3rem;
        }
        .download-code {
          width: 1.925rem;
          height: 1.9625rem;
          position: absolute;
          top: 0.5625rem;
          right: -0.35rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .mark-boxs {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      position: fixed;
      top: 0rem;
      left: 0rem;
      z-index: 999999999;
      .mark {
        width: 11.625rem;
        height: 10.6625rem;
        background: #ffffff;
        position: fixed;
        left: calc(50% - 5.8125rem);
        top: calc(50% - 5.3312rem);
        z-index: 9899999999999;
        .mark-content {
          display: flex;
          flex-direction: column;
          // justify-content: center;
          // align-items: center;
          position: relative;
          .top {
            width: 11.625rem;
            height: 2.175rem;
            background: url(../../assets/img/home-flag.png) 100%;
            position: relative;
            .title {
              width: 8.1125rem;
              height: 0.5625rem;
              font-size: 0.4rem;
              font-weight: 500;
              color: #0057fe;
              margin: 0.75rem 0rem 0rem 1.7625rem;
            }
            .descript {
              width: 8.1125rem;
              height: 0.275rem;
              font-size: 0.2rem;
              font-weight: 500;
              color: #999999;
              margin: 0.25rem 0rem 0rem 1.7625rem;
            }
            img {
              width: 0.45rem;
              height: 0.45rem;
              position: absolute;
              top: 0.375rem;
              right: 0.375rem;
            }
          }
          ::v-deep {
            .form {
              margin-top: 0.25rem;
              margin-left: 0rem;
              .el-form-item__content {
                width: 5.7125rem;
                height: 0.85rem;
                .el-input__inner {
                  height: 0.85rem;
                }
              }
              .el-form-item {
                width: 8.1125rem;
                margin-top: 0.25rem;
                text-align: left;
              }
              margin-left: 0.8rem;
              .el-input_inner {
                width: 8.1125rem;
              }
            }
            .custom-form-item {
              width: 5.25rem;
            }
            .custom-form-item .code {
              width: 2.5rem;
              height: 0.85rem;
              font-size: 0.225rem;
              font-weight: 400;
              color: #ffffff;
              background: #0057fe;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              left: 3.3rem;
              top: 0;
            }
          }
          .submit {
            width: 3.125rem;
            height: 0.85rem;
            font-size: 0.225rem;
            color: #ffffff;
            background: #0057fe;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.75rem;
            margin-left: 4.25rem;
          }
        }
      }
    }
  }
}
.header-wrap {
  width: 100%;
  height: 0.875rem;
  position: fixed;
  // background: rgba(0, 0, 0, 0.1);
  // min-width: 15.375rem;
  // overflow-x: auto;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  .header-content {
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    // margin-left: 3.25rem;
    .logo {
      width: 2.0375rem;
      height: 0.5rem;
      margin-right: 1.4125rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .nav {
      width: calc(100% - 2.8rem);
      height: 0.875rem;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      ul {
        width: 8rem;
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        li {
          width: 1.25rem;
          text-align: center;
          display: inline-block;
          line-height: 1rem;
          // margin-right: .825rem;
          text-decoration: none;
          font-size: 0.2rem;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          .active {
            font-weight: bold;
            color: #0057fe;
            // border-bottom: .025rem solid #000;
          }
          .span {
            width: 32px;
            height: 0.05rem;
            background: #0057fe;
            display: block;
            text-align: center;
            margin-top: -0.2rem;
          }
          .li-item {
            width: 1.25rem;

            // width: 1.25rem;
            background: #ffffff;
            // border-radius: 15px;
            z-index: 1000;
            // padding: 0px 10px;
            position: absolute;
            // margin-left: -0.2rem;
            // text-align: center;
            list-style: none;
            // line-height: 30px;
            display: flex;
            flex-direction: column;
            margin-top: 0.1rem;
            margin-left: -0.58rem;
            li {
              // width: 120px;
              height: 0.4375rem;
              line-height: 0.4375rem;
              font-size: 14px;
              font-weight: 400;
              color: #000000;
            }
            li:hover {
              font-weight: bold;
              color: #0057fe;
            }
          }
        }
      }
      .phone {
        display: flex;
        margin-left: 0.3rem;
        img {
          width: 0.2rem;
          height: 0.2rem;
          margin-top: 0.0375rem;
        }
        span {
          display: block;
          width: 1.325rem;
          height: 0.25rem;
          font-size: 0.2rem;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.25rem;
          margin-left: 0.0625rem;
        }
      }
      .free {
        width: 1.2rem;
        height: 0.45rem;
        font-size: 0.15rem;
        color: #ffffff;
        background: #0057fe;
        border-radius: 0.225rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.3rem;
      }
      .download {
        position: relative;
        .download-app {
          width: 1.2rem;
          height: 0.45rem;
          font-size: 0.15rem;
          color: #ffffff;
          background: #34d7e8;
          border-radius: 0.225rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0.3rem;
        }
        .download-code {
          width: 1.925rem;
          height: 1.9625rem;
          position: absolute;
          top: 0.5625rem;
          right: -0.35rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .mark-boxs {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      position: fixed;
      top: 0rem;
      left: 0rem;
      z-index: 999999999;
      .mark {
        width: 11.625rem;
        height: 10.6625rem;
        background: #ffffff;
        position: fixed;
        left: calc(50% - 5.8125rem);
        top: calc(50% - 5.3312rem);
        z-index: 9899999999999;
        .mark-content {
          display: flex;
          flex-direction: column;
          // justify-content: center;
          // align-items: center;
          position: relative;
          .top {
            width: 11.625rem;
            height: 2.175rem;
            background: url(../../assets/img/home-flag.png) 100%;
            position: relative;
            .title {
              width: 8.1125rem;
              height: 0.5625rem;
              font-size: 0.4rem;
              font-weight: 500;
              color: #0057fe;
              margin: 0.75rem 0rem 0rem 1.7625rem;
            }
            .descript {
              width: 8.1125rem;
              height: 0.275rem;
              font-size: 0.2rem;
              font-weight: 500;
              color: #999999;
              margin: 0.25rem 0rem 0rem 1.7625rem;
            }
            img {
              width: 0.45rem;
              height: 0.45rem;
              position: absolute;
              top: 0.375rem;
              right: 0.375rem;
            }
          }
          ::v-deep {
            .form {
              margin-top: 0.25rem;
              margin-left: 0rem;
              .el-form-item__content {
                width: 5.7125rem;
                height: 0.85rem;
                .el-input__inner {
                  height: 0.85rem;
                }
              }
              .el-form-item {
                width: 8.1125rem;
                margin-top: 0.25rem;
                text-align: left;
              }
              margin-left: 0.8rem;
              .el-input_inner {
                width: 8.1125rem;
              }
            }
            .custom-form-item {
              width: 5.25rem;
            }
            .custom-form-item .code {
              width: 2.5rem;
              height: 0.85rem;
              font-size: 0.225rem;
              font-weight: 400;
              color: #ffffff;
              background: #0057fe;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              left: 3.3rem;
              top: 0;
            }
          }
          .submit {
            width: 3.125rem;
            height: 0.85rem;
            font-size: 0.225rem;
            color: #ffffff;
            background: #0057fe;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.75rem;
            margin-left: 4.25rem;
          }
        }
      }
    }
  }
}
</style>
