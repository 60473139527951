<template>
  <div class="footer-wrap">
    <div class="top">
      <div class="logo">
        <div class="img">
          <img src="../../assets/img/logo_2.png" alt="" />
        </div>
        <div class="site">
          <div class="left">地址：</div>
          <div class="right">河北省邯郸市滏东北大街555号工业设计中心2楼</div>
        </div>
        <div class="phone">
          <div class="left">电话：</div>
          <div class="right">0310-7058999</div>
        </div>
      </div>
      <div class="list">
        <div>
          <ul>
            <li>解决方案</li>
            <li @click="solutionClick(1)">企业差旅</li>
            <li @click="solutionClick(2)">企业用车</li>
            <li @click="solutionClick(3)">商务宴请用餐</li>
            <li @click="solutionClick(4)">差旅拓客</li>
            <li @click="solutionClick(5)">政企招商考察</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>产品与服务</li>
            <li>服务保障</li>
            <li>实施管控</li>
            <li>对公结算</li>
            <li>安全保障</li>
            <li @click="customer">7*24h 在线客户</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>发现更多</li>
            <li @click="center">资讯中心</li>
            <li @click="cases">客户案例</li>
          </ul>
        </div>
      </div>
      <div class="code">
        <div class="div">
          <img src="../../assets/img/codeOne.png" alt="" />
          <span>差游金海App下载</span>
        </div>
        <div class="div">
          <img src="../../assets/img/codeOne.png" alt="" />
          <span>扫码关注我们</span>
        </div>
      </div>
    </div>
    <div class="horizontal"></div>
    <div class="bot">
      <span
        >2023-2023 chaiyeee.com版权所有, 差爷商帮（邯郸）科技有限公司-
        <span class="span" @click="filings">冀ICP备2023039925号-1</span>
      </span>
      <div @click="filing">
        <img src="../../assets/img/national.png" alt="" />
        <span>冀公网安备13040302001616号</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    solutionClick(index) {
      this.$router.push('/solution').catch((err) => {});
    },
    // 资讯中心
    center() {
      this.$router.push('/consulting').catch((err) => {});
    },
    // 客户案例
    cases() {
      this.$router.push('/solution').catch((err) => {});
    },
    //客服
    customer() {
      window.open(
        'https://kf.ruanzhongzi.com/pcKefu/?propType=1&kefuId=chaiyeshangbang  ',
        '_blank'
      );
    },
    // 备案号跳转其他链接
    filings() {
      window.open('https://beian.miit.gov.cn/');
    },
    //新窗口打开公安号
    filing() {
      window.open(
        'https://beian.mps.gov.cn/#/query/webSearch?code=13040302001616',
        '_blank'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-wrap {
  width: 100%;
  height: 8.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: red;
  .top {
    width: 17.5rem;
    display: flex;
    justify-content: space-around;
    margin-top: 1.2625rem;
    // margin: 1.25rem 0rem 0rem 0rem;
    .logo {
      width: 2.525rem;
      font-size: 0.15rem;
      margin-top: 0.275rem;

      .img {
        width: 2.5rem;
        height: 0.625rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .site {
        color: #828ca0;
        margin-top: 0.375rem;
        display: flex;
        .left {
          width: 0.8rem;
        }
      }
      .phone {
        color: #828ca0;
        margin-top: 0.1875rem;
        display: flex;
      }
    }
    .list {
      width: 5.5rem;
      display: flex;
      margin-left: 3.0125rem;
      div {
        margin-right: 1.25rem;
        ul li:first-child {
          font-size: 0.2rem;
          color: #000000;
        }
        ul {
          width: 1.25rem;
          list-style: none;
          line-height: 0.375rem;
          padding: 0;
          margin: 0;
          li {
            cursor: pointer;
            font-size: 0.175rem;
            color: #828ca0;
          }
          li:hover {
            color: blue;
          }
        }
      }
    }
    .code {
      width: 3.375rem;
      display: flex;
      margin-left: 3.25rem;
      div {
        margin-right: 0.375rem;
        display: flex;
        flex-direction: column;
        img {
          width: 1.5rem;
          height: 1.5rem;
        }
        span {
          margin-top: 0.0625rem;
          font-size: 0.175rem;
          color: #828ca0;
          text-align: center;
        }
      }
    }
  }
  .horizontal {
    width: 17.5rem;
    height: 0.0125rem;
    background: #e8ecf5;
    border-radius: 0.025rem;
    margin-top: 2.7625rem;
  }
  .bot {
    text-align: center;
    color: #828ca0;
    margin-top: 0.475rem;
    cursor: pointer; /* 显示小手图标 */
    display: flex;
    align-items: center;
    .span {
      color: #3760a9;
    }
    div {
      margin-left: 0.1rem;
      display: flex;
      align-items: center;
      span {
        margin-left: 0.05rem;
        margin-top: 0.01rem;
      }
    }
    img {
      width: 0.15rem;
      height: 0.15rem;
    }
  }
}
</style>
